<template>
  <div 
    :class="{ mini }"
    class="badges d-flex flex-wrap justify-center align-center"
    @click="click"
  >
    <v-tooltip
      v-for="(item, i) in list"
      :key="'badge-'+item.key"
      top
      max-width="60vw"
      content-class="text-center elevation-6"
      color="grey darken-3"
    >
      <template v-slot:activator="{ on, attrs }">
        <icon-base
          width="96"
          height="113"
          :name="'badge-'+item.key"
          :class="{ ...item }"
          class="badge mx-1 my-2"
          v-bind="attrs"
          v-on="on"
          @click.stop="e => click(e, item.key)"
        >
          <component 
            :is="item.key"
          />
        </icon-base>
      </template>
      {{ hints[item.key] }}
    </v-tooltip>
    <v-chip
      v-show="limited>0"
      class="mx-1"
    >
      + {{ limited }}
      <!-- <span color="warning--text font-weight-bold">
        {{ completion.won }}
      </span> / {{ completion.total }} -->
    </v-chip>
  </div>
</template>

<style lang="scss">

.badges {
  padding-top: 8px;

  .badge {
    width: 24vw;
    // transition: width .5s ease-out;

    &:not(.toggle) {
      opacity: .48;
      &:focus {
        opacity: 1;
      }

      .green, .green * {
        fill: var(--v-grey-ligthen-3);
      } 
      .blue, .blue * {
        fill: var(--v-grey-darken-3);
      } 
      .surface, .surface * {
        fill: var(--v-grey-base);
      } 
      .badge-bg {
        fill: var(--v-grey-darken-3);
      } 
      .badge-inner-bg {
        fill: var(--v-grey-base);
      } 
      .badge-pattern path {
        fill: var(--v-grey-darken-4);
      }
      .hexs path {
        fill: var(--v-grey-base);
      }
      .medal path {
        fill: var(--v-grey-base);
      }
      .text {
        fill: var(--v-grey-base);
      }
    }
  }
  &.mini {
    padding: 0;

    .badge {
      width: 16vw;
    }
  }
}

</style>

<script>
  export default {
    props: {
      items: {
        type: Array,
        default: () => []
      },
      mini: {
        type: Boolean,
        default: false,
      },
      max: {
        type: Number,
        default: null,
      }
    },

    data: () => ({
      controller: {
        items: {}
      },
      dict: {
        'nome': 'key',
        'pontos': 'points',
        'valor': 'toggle',
        '1_ano': 'since1',
        '2_ano': 'since2',
        '3_ano': 'since3',
        '4_ano': 'since4',
        '5_ano': 'since5',
        'top_1': 'top1',
        'top_2': 'top2',
        'top_3': 'top3',
        'pontual': 'punctual',
        'do_clube': 'clubber',
        'seguro': 'safe',
        'disponivel': 'available',
        'comprometido': 'consistent',
        'remunerado': 'paid',
      },
      hints: {
        'since1': '1 ano de Mobees',
        'since2': '2 ano de Mobees',
        'since3': '3 ano de Mobees',
        'since4': '4 ano de Mobees',
        'since5': '5 ano de Mobees',
        'top1': 'Parceiro que mais rodou no último ciclo',
        'top2': 'Segundo que mais rodou no último ciclo',
        'top3': 'Terceiro que mais rodou no último ciclo',
        'punctual': 'Sem faltas ou remarcações nos últimos 90 dias',
        'clubber': 'Utilizou 3 ou mais benefícios do Clube Mobees nos últimos 90 dias',
        'safe': 'Revisões em dia nos últimos 90 dias',
        'available': 'Participou de eventos nos últimos 90 dias',
        'consistent': 'Completou os últimos 3 ciclos',
        'paid': 'Completou o último ciclo',
      }
    }),

    computed: {
      list () {
        const max = this.max||3;
        const items = _.orderBy(_.values(_.clone(this.controller.items)), ['toggle', 'key'], ['desc', 'desc']);
        const list = this.mini ? _.take(items, max) : items;
        return list;
      },

      limited () {
        return _.size(this.items) - _.size(this.list);
      },

      completion () {
        const items = this.controller.items;
        return {
          won: _.size(_.filter(items, ['toggle', 1])),
          total: _.size(items)
        }
      },
      
    },

    watch: {
      items: {
        immediate: true,
        deep: true,
        handler (items) {
          const dict = this.dict;
          this.controller.items = _.keyBy(_.mapValues(_.clone(items), item => {
            return _.mapValues(_.mapKeys(item, (v,k) => dict[k]), v => _.has(dict, v) ? dict[v] : v);
          }), 'key');
        }
      }
    },
    methods: {
      click (event, key=null) {
        this.$emit('click', event, key);
      }
    },

    components: {
      available: () => import('@/components/badges/available'),
      clubber: () => import('@/components/badges/clubber'),
      consistent: () => import('@/components/badges/consistent'),
      paid: () => import('@/components/badges/paid'),
      punctual: () => import('@/components/badges/punctual'),
      safe: () => import('@/components/badges/safe'),
      since1: () => import('@/components/badges/since-1'),
      since2: () => import('@/components/badges/since-2'),
      since3: () => import('@/components/badges/since-3'),
      since4: () => import('@/components/badges/since-4'),
      since5: () => import('@/components/badges/since-5'),
      top1: () => import('@/components/badges/top-1'),
      top2: () => import('@/components/badges/top-2'),
      top3: () => import('@/components/badges/top-3'),
    }
  }
</script>